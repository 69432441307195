<template>
    <div class="detail">
        <div class="big-title">
            {{info.devName || '加载中...'}} - {{info.devCode}}
        </div>
        <div class="header">
            <img src="@/assets/wgs/img/show.jpg" alt="设备图">
        </div>
        <div class="area-alarm" v-if="info.devStatus === 'alarm'">
            <div class="alarm-content">
                <div>发生{{alarmData.alarmType ===1 ?'撞击':'倾倒'}}告警</div>
                <div>{{alarmData.uploadTime || '0000-00-00 00:00:00'}}</div>
                <div class="btn" @click="cancelAlarm">解除告警</div>
            </div>
        </div>
        <div class="nav-title">设备信息</div>
        <div class="base-info">
            <van-row gutter="20">
                <van-col span="12">电池电压：{{info.cycleData ? (info.cycleData.batVolt/1000).toFixed(2) : 0 }}V</van-col>
                <van-col span="12">设备桩号：{{info.devStake || '暂无信息'}}</van-col>
            </van-row>
            <van-row gutter="20">
                <van-col span="12">设备状态：{{
                    info.devStatus === 'online' ?'在线' : 
                    info.devStatus === 'alarm' ?'告警' : 
                    info.devStatus === 'fault' ?'故障' : '离线' 
                }}</van-col>
                <van-col span="12">设备类型：{{info.devType || '暂无信息'}}</van-col>
            </van-row>
            <van-row gutter="20">
                <van-col span="12">硬件版本：v{{info.cycleData ? info.cycleData.hardwareVersion : '1.0'}}</van-col>
                <van-col span="12">软件版本：v{{info.cycleData ? info.cycleData.softwareVersion : '1.0'}}</van-col>
            </van-row>
            <van-row gutter="20">
                <van-col span="12">地理经度：{{info.lon || 0}}</van-col>
                <van-col span="12">地理纬度：{{info.lat || 0}}</van-col>
            </van-row>
            <van-row gutter="20">
                <van-col span="24">IMEI编号：{{info.cycleData ? info.cycleData.imei : '暂无信息'}}</van-col>
            </van-row>
            <van-row gutter="20">
                <van-col span="24">所属道路：{{info.devRoad || '暂无信息'}}</van-col>
            </van-row>
            <van-row gutter="20">
                <van-col span="24">安装位置：{{info.location || '暂无信息'}}</van-col>
            </van-row>
            <van-row gutter="20">
                <van-col span="24">安装日期：{{info.devInstallDate || '0000-00-00 00:00:00'}}</van-col>
            </van-row>
        </div>
        
    </div>
</template>

<script>
export default {
    data(){
        return{
            info:{
                id:null,
                devStatus:'offline'
            },
            alarmData:{}
        }
    },
    mounted(){
        this.getBaseInfo();
    },
    methods:{
        // 获取设备基础信息
        getBaseInfo(){
            this.$api.WGS.deviceSingleInfo({
                devCode:this.$route.query.devCode
            }).then((data) => {
                this.info = { ...data[0] }
                if(data[0].devStatus === 'alarm'){
                    this.getAlarm();
                }
            })
        },
        // 获取最新一条告警信息
        getAlarm(){
            this.$api.WGS.newAlarmLastOne({
                devCode:this.$route.query.devCode
            }).then((data) => {
                this.alarmData = { ...data[0] }
            })
        },
        // 解除告警
        cancelAlarm(){
            this.$api.WGS.updateAlarmItem({
                id:this.info.id,
                devStatus:'online'
            }).then((data) => {
                this.$toast('解除成功！');
                setTimeout(()=>{
                    this.getBaseInfo();
                },500)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.detail{
    .big-title{
        font-size: 1.2rem;
        font-weight: bolder;
        letter-spacing: .5px;
        text-align: center;
        padding: 10px 0;
    }
    .header{
        padding: 10px;
        img{
            border: 1px solid #ccc;
            border-radius: 10px;
            width: 100%;
            height: 16rem;
        }
    }
    .nav-title{
        padding-left: 8px;
        border-left: 5px solid #2c6a8e;
        margin: 10px;
        text-align: left;
        font-size: .95rem;
        font-weight: bolder;
    }
    .base-info{
        padding: 10px;
        font-size: .9rem;
        text-align: left;
        .van-row{
            margin-bottom: 10px;
        }
    }
    .area-alarm{
        padding: 10px;
        background: #ff7736;
        margin: 10px;
        border-radius: 6px;
        font-size: .85rem;
        .alarm-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
        }
        .btn{
            padding: 3px 12px;
            background: #28c088;
            border-radius: 5px;
            font-size: .75rem;
            &:active{
                opacity: 0.8;
            }
        }
    }
}
</style>